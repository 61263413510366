
<template>
  <div class="footer-wrapper">
    <div id="footer">
      <div class="container">
        <div class="top text-center">
          <div class="image-wrapper">
            <img
              src="../../../assets/lc_img/Lets_Connect_with_text_white.png"
              alt="alt"
            >
          </div>
        </div>

        <div class="middle">
          <v-row>
            <v-col
              cols="12"
              lg="7"
              sm="7"
            >
              <v-row>
                <v-col
                  cols="12"
                  lg="4"
                >
                  <div class="footer-title">
                    Let's Connect
                  </div>
                  <ul class="footer-list">
                    <li><a href="#">About us</a></li>
                    <li><a href="#">Support</a></li>
                    <li><a href="#">Service status</a></li>
                  </ul>
                </v-col>

                <v-col
                  cols="12"
                  lg="4"
                >
                  <div class="footer-title">
                    Products
                  </div>
                  <ul class="footer-list">
                    <li><a href="#">SMS services</a></li>
                    <li><a href="#">MMS services</a></li>
                    <li><a href="#">Video Services</a></li>
                  </ul>
                </v-col>

                <v-col
                  cols="12"
                  lg="4"
                >
                  <div class="footer-title">
                    Pricing
                  </div>
                  <ul class="footer-list">
                    <li><a href="#">Subscription Options</a></li>
                  </ul>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              lg="5"
              sm="5"
            >
              <v-row>
                <v-col
                  cols="12"
                >
                  <div class="footer-section mb-4">
                    <div class="footer-title">
                      Join our news letter
                    </div>
                    <form action="">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter your email address"
                      >
                      <input
                        type="submit"
                        class="submit-button"
                        value="Subscribe"
                      >
                    </form>
                  </div>

                  <div class="footer-section">
                    <div class="footer-title">
                      Follow us on social media
                    </div>
                    <ul class="social-list">
                      <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                      <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                      <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                      <li><a href="#"><i class="fa fa-whatsapp"></i></a></li>
                    </ul>
                  </div>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </div>

        <div class="bottom">
          <v-row
            class="align-items-center"
          >
            <v-col
              cols="12"
              lg="10"
            >
              <span class="foot-text">
                Lets Connect® is a 100% opt-in service. Please see our Privacy Policy, Terms Of Use, Services Policy Anti-Spam Policy to learn about our position on SPAM and the privacy of your data. Msg&data rates may apply in the US. Standard message and data rates apply in Canada. To unsubscribe from an Lets Connect® list, simply text ‘STOP’ to the originating short code or contact Support. Lets Connect® neither provides lists of phone numbers nor do we access our clients' contact lists.
              </span>
            </v-col>

            <v-col
              cols="12"
              lg="2"
            >
              <div class="text-md-right text-center">
                <img
                  src="../../../assets/lc_img/footer-img.png"
                  alt=""
                >
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'PublicFooter',
  }

</script>
<style lang="scss" scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css');
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --mona-lisa: #fa9;
  --cotton-candy: #ffb8f4;
  --malibu: #7ecafb;
  --input-bg: #fbfbfb;
  --search-bg: #f8fcff;
  --line: #f7f7f7;
  --highlight-blue: #47b3f9;
  --pricing-table-head: #4978fa;
  --pricing-table-row-odd: #f3f9fe;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --sms: #47b4f9;
  --mms: #420fb7;
  --voice: #e588d8;
  --email: #74efcf;
  --fax: #c15bda;
  --post: #fbc98e;
  --flyers: #fd8671;
  --flyers1: #ff866d;
  --white: #fff;
  --martinique: #32325c;
  --bali-hai: #8ca0b5;
  --bali-hai2: #f3f8fd;
  --maya-blue: #47b4f9;
  --maya-blue-btn: #4d7df6;
  --alice-blue: #f8fbff;
  --salmon: #fbc98e;
  --salmon1: #f9b25f;
  --salmon-btn: #fd8671;
  --medium-orchid: #c15bda;
  --medium-orchid1: #da56c8;
  --clicksend-blue: #00a5ff;
  --text-color: #8a9eb3;
  --muted: #f3f8fd;
  --shocking: #e588d8;
  --cotton-candy1: #ff9aef;
  --spray: #74efcf;
  --spray1: #38e8ba;
  --breakdown-bg: #f3f9fe;
  --hero: #31315c;
  --gettingstarted-btn: #47b4f9;
  --mustard: #ffd44f;
  --bright-turquoise: #32e2f5;
  --hollywood-cerise: #ca00c2;
  --cerise: #e648ad;
  --medium-purple: #a75fe5;
  --lavender-rose: #ff9aef;
  --dodger-blue: #4a79fa;
  --electric-violet: #792bfb;
  --turquoise: #37e7b7;
  --gull-gray: #99a8b7;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Open Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

footer {
  display: block
}

body {
  margin: 0;
  font-family: Open Sans, sans-serif;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #8a9eb3;
  text-align: left;
  background-color: #fff
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important
}

.social-icons {
  a {
    display: block;
    width: 35px;
    height: 35px;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    margin: auto;
  }
}

.site-footer-bottom {
  background: #b97d25 !important;
  text-align: justify;
  p{
    font-size: 1rem;
    color: #fff;
  }
}

.f-brand {
  padding: 2rem 0rem;
  .brands-section {
    padding: 1rem 0rem;
    width: 100%;
    img {
      height: 5.75rem;
      object-fit: contain;
      margin: 0rem 1rem 0rem 0rem;
    }
  }

  .news-letter {
    width: 100%;
    button {
      // width: 35%;
      height: 3rem;
    }

    img {
      width: 40%;
      height: 3rem;
      margin: 0rem 0rem 0rem 1rem;
    }
  }
}

.footer-links-title {
  color: #990000;
  font-weight: 600;
}

@media screen and (max-width:991.98px) {
  .social-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem !important;
  }
}

@media screen and (max-width:767.98px) {
  .footer-menu-align {
    text-align: center
  }
}

.footer-bg {
  background: #f9a932 !important;
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

ul {
  margin-bottom: 1rem
}

ul {
  margin-top: 0
}

// a {
//   color: #47b4f9;
//   text-decoration: none;
//   background-color: transparent
// }

// a:hover {
//   color: #0893ec;
//   text-decoration: underline
// }

img {
  border-style: none
}

img {
  vertical-align: middle
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label:before {
  border-color: #28a745
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label:before {
  border-color: #dc3545
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer
}

.custom-control-input:focus:not(:checked)~.custom-control-label:before {
  border-color: #80bdff
}

.custom-control-input:not(:disabled):active~.custom-control-label:before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: .75
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important
}

@supports ((position: -webkit-sticky) or (position:sticky)) {
}

.w-75 {
  width: 75% !important
}

.mb-1 {
  margin-bottom: .25rem !important
}

.my-2 {
  margin-top: .5rem !important
}

.mb-2,
.my-2 {
  margin-bottom: .5rem !important
}

.mt-3 {
  margin-top: 1rem !important
}

.mb-13 {
  margin-bottom: 13px !important
}

.mr-15 {
  margin-right: 15px !important
}

.mt-16 {
  margin-top: 16px !important
}

.text-text-color {
  color: #8a9eb3 !important
}

a.text-text-color:focus,
a.text-text-color:hover {
  color: #5f7892 !important
}

@media print {

  *,
  :after,
  :before {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
  }

  a:not(.btn) {
    text-decoration: underline
  }

  img {
    page-break-inside: avoid
  }

  p {
    orphans: 3;
    widows: 3
  }

  @page {
    size: a3
  }

  body {
    min-width: 992px !important
  }
}

.font-size-13 {
  font-size: 13px !important
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem hsla(208, 6%, 54%, .5);
  box-shadow: 0 0 0 .2rem hsla(208, 6%, 54%, .5)
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5);
  box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5)
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5);
  box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5)
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(222, 170, 12, .5);
  box-shadow: 0 0 0 .2rem rgba(222, 170, 12, .5)
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5);
  box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5)
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem hsla(220, 4%, 85%, .5);
  box-shadow: 0 0 0 .2rem hsla(220, 4%, 85%, .5)
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5);
  box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-sms:not(:disabled):not(.disabled).active,
.btn-sms:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #16a0f7;
  border-color: #099bf7
}

.btn-sms:not(:disabled):not(.disabled).active:focus,
.btn-sms:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(65, 159, 218, .5);
  box-shadow: 0 0 0 .2rem rgba(65, 159, 218, .5)
}

.btn-mms:not(:disabled):not(.disabled).active,
.btn-mms:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #310b88;
  border-color: #2d0a7c
}

.btn-mms:not(:disabled):not(.disabled).active:focus,
.btn-mms:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(94, 51, 194, .5);
  box-shadow: 0 0 0 .2rem rgba(94, 51, 194, .5)
}

.btn-voice:not(:disabled):not(.disabled).active,
.btn-voice:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #dc5eca;
  border-color: #da54c7
}

.btn-voice:not(:disabled):not(.disabled).active:focus,
.btn-voice:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(200, 121, 190, .5);
  box-shadow: 0 0 0 .2rem rgba(200, 121, 190, .5)
}

.btn-email:not(:disabled):not(.disabled).active,
.btn-email:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #46eabf;
  border-color: #3be8bb
}

.btn-email:not(:disabled):not(.disabled).active:focus,
.btn-email:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(104, 209, 182, .5);
  box-shadow: 0 0 0 .2rem rgba(104, 209, 182, .5)
}

.btn-fax:not(:disabled):not(.disabled).active,
.btn-fax:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #b131d1;
  border-color: #aa2dc8
}

.btn-fax:not(:disabled):not(.disabled).active:focus,
.btn-fax:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(202, 116, 224, .5);
  box-shadow: 0 0 0 .2rem rgba(202, 116, 224, .5)
}

.btn-post:not(:disabled):not(.disabled).active,
.btn-post:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #f9b15d;
  border-color: #f9ac50
}

.btn-post:not(:disabled):not(.disabled).active:focus,
.btn-post:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem hsla(32, 55%, 68%, .5);
  box-shadow: 0 0 0 .2rem hsla(32, 55%, 68%, .5)
}

.btn-flyers:not(:disabled):not(.disabled).active,
.btn-flyers:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #fc5b3f;
  border-color: #fc5032
}

.btn-flyers:not(:disabled):not(.disabled).active:focus,
.btn-flyers:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem hsla(9, 63%, 63%, .5);
  box-shadow: 0 0 0 .2rem hsla(9, 63%, 63%, .5)
}

.btn-flyers1:not(:disabled):not(.disabled).active,
.btn-flyers1:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #ff5c3a;
  border-color: #ff512d
}

.btn-flyers1:not(:disabled):not(.disabled).active:focus,
.btn-flyers1:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(222, 119, 99, .5);
  box-shadow: 0 0 0 .2rem rgba(222, 119, 99, .5)
}

.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #e6e5e5;
  border-color: #dfdfdf
}

.btn-white:not(:disabled):not(.disabled).active:focus,
.btn-white:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem hsla(240, 2%, 87%, .5);
  box-shadow: 0 0 0 .2rem hsla(240, 2%, 87%, .5)
}

.btn-martinique:not(:disabled):not(.disabled).active,
.btn-martinique:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #20203b;
  border-color: #1c1c33
}

.btn-martinique:not(:disabled):not(.disabled).active:focus,
.btn-martinique:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(81, 81, 116, .5);
  box-shadow: 0 0 0 .2rem rgba(81, 81, 116, .5)
}

.btn-bali-hai:not(:disabled):not(.disabled).active,
.btn-bali-hai:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #6d86a1;
  border-color: #65809c
}

.btn-bali-hai:not(:disabled):not(.disabled).active:focus,
.btn-bali-hai:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(124, 142, 160, .5);
  box-shadow: 0 0 0 .2rem rgba(124, 142, 160, .5)
}

.btn-bali-hai2:not(:disabled):not(.disabled).active,
.btn-bali-hai2:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #c7dff6;
  border-color: #bcd8f4
}

.btn-bali-hai2:not(:disabled):not(.disabled).active:focus,
.btn-bali-hai2:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(212, 216, 221, .5);
  box-shadow: 0 0 0 .2rem rgba(212, 216, 221, .5)
}

.btn-maya-blue:not(:disabled):not(.disabled).active,
.btn-maya-blue:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #16a0f7;
  border-color: #099bf7
}

.btn-maya-blue:not(:disabled):not(.disabled).active:focus,
.btn-maya-blue:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(65, 159, 218, .5);
  box-shadow: 0 0 0 .2rem rgba(65, 159, 218, .5)
}

.btn-maya-blue-btn:not(:disabled):not(.disabled).active,
.btn-maya-blue-btn:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #1c5af4;
  border-color: #1051f3
}

.btn-maya-blue-btn:not(:disabled):not(.disabled).active:focus,
.btn-maya-blue-btn:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(104, 145, 247, .5);
  box-shadow: 0 0 0 .2rem rgba(104, 145, 247, .5)
}

.btn-alice-blue:not(:disabled):not(.disabled).active,
.btn-alice-blue:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #c5deff;
  border-color: #b8d7ff
}

.btn-alice-blue:not(:disabled):not(.disabled).active:focus,
.btn-alice-blue:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(216, 219, 223, .5);
  box-shadow: 0 0 0 .2rem rgba(216, 219, 223, .5)
}

.btn-salmon:not(:disabled):not(.disabled).active,
.btn-salmon:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #f9b15d;
  border-color: #f9ac50
}

.btn-salmon:not(:disabled):not(.disabled).active:focus,
.btn-salmon:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem hsla(32, 55%, 68%, .5);
  box-shadow: 0 0 0 .2rem hsla(32, 55%, 68%, .5)
}

.btn-salmon1:not(:disabled):not(.disabled).active,
.btn-salmon1:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #f79a2e;
  border-color: #f79422
}

.btn-salmon1:not(:disabled):not(.disabled).active:focus,
.btn-salmon1:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(217, 157, 87, .5);
  box-shadow: 0 0 0 .2rem rgba(217, 157, 87, .5)
}

.btn-salmon-btn:not(:disabled):not(.disabled).active,
.btn-salmon-btn:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #fc5b3f;
  border-color: #fc5032
}

.btn-salmon-btn:not(:disabled):not(.disabled).active:focus,
.btn-salmon-btn:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem hsla(9, 63%, 63%, .5);
  box-shadow: 0 0 0 .2rem hsla(9, 63%, 63%, .5)
}

.btn-medium-orchid:not(:disabled):not(.disabled).active,
.btn-medium-orchid:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #b131d1;
  border-color: #aa2dc8
}

.btn-medium-orchid:not(:disabled):not(.disabled).active:focus,
.btn-medium-orchid:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(202, 116, 224, .5);
  box-shadow: 0 0 0 .2rem rgba(202, 116, 224, .5)
}

.btn-medium-orchid1:not(:disabled):not(.disabled).active,
.btn-medium-orchid1:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #d02db9;
  border-color: #c52bb0
}

.btn-medium-orchid1:not(:disabled):not(.disabled).active:focus,
.btn-medium-orchid1:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(224, 111, 208, .5);
  box-shadow: 0 0 0 .2rem rgba(224, 111, 208, .5)
}

.btn-clicksend-blue:not(:disabled):not(.disabled).active,
.btn-clicksend-blue:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0084cc;
  border-color: #007cbf
}

.btn-clicksend-blue:not(:disabled):not(.disabled).active:focus,
.btn-clicksend-blue:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(38, 179, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(38, 179, 255, .5)
}

.btn-text-color:not(:disabled):not(.disabled).active,
.btn-text-color:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #6b849f;
  border-color: #647e9a
}

.btn-text-color:not(:disabled):not(.disabled).active:focus,
.btn-text-color:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(122, 140, 158, .5);
  box-shadow: 0 0 0 .2rem rgba(122, 140, 158, .5)
}

.btn-muted:not(:disabled):not(.disabled).active,
.btn-muted:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #c7dff6;
  border-color: #bcd8f4
}

.btn-muted:not(:disabled):not(.disabled).active:focus,
.btn-muted:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(212, 216, 221, .5);
  box-shadow: 0 0 0 .2rem rgba(212, 216, 221, .5)
}

.btn-shocking:not(:disabled):not(.disabled).active,
.btn-shocking:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #dc5eca;
  border-color: #da54c7
}

.btn-shocking:not(:disabled):not(.disabled).active:focus,
.btn-shocking:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(200, 121, 190, .5);
  box-shadow: 0 0 0 .2rem rgba(200, 121, 190, .5)
}

.btn-cotton-candy1:not(:disabled):not(.disabled).active,
.btn-cotton-candy1:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #ff67e7;
  border-color: #ff5ae5
}

.btn-cotton-candy1:not(:disabled):not(.disabled).active:focus,
.btn-cotton-candy1:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(222, 136, 209, .5);
  box-shadow: 0 0 0 .2rem rgba(222, 136, 209, .5)
}

.btn-spray:not(:disabled):not(.disabled).active,
.btn-spray:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #46eabf;
  border-color: #3be8bb
}

.btn-spray:not(:disabled):not(.disabled).active:focus,
.btn-spray:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(104, 209, 182, .5);
  box-shadow: 0 0 0 .2rem rgba(104, 209, 182, .5)
}

.btn-spray1:not(:disabled):not(.disabled).active,
.btn-spray1:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #19d4a3;
  border-color: #17c99b
}

.btn-spray1:not(:disabled):not(.disabled).active:focus,
.btn-spray1:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(53, 203, 164, .5);
  box-shadow: 0 0 0 .2rem rgba(53, 203, 164, .5)
}

.btn-breakdown-bg:not(:disabled):not(.disabled).active,
.btn-breakdown-bg:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #c4e1fa;
  border-color: #b8dcf9
}

.btn-breakdown-bg:not(:disabled):not(.disabled).active:focus,
.btn-breakdown-bg:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(212, 217, 222, .5);
  box-shadow: 0 0 0 .2rem rgba(212, 217, 222, .5)
}

.btn-hero:not(:disabled):not(.disabled).active,
.btn-hero:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #1f1f3b;
  border-color: #1b1b32
}

.btn-hero:not(:disabled):not(.disabled).active:focus,
.btn-hero:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(80, 80, 116, .5);
  box-shadow: 0 0 0 .2rem rgba(80, 80, 116, .5)
}

.btn-gettingstarted-btn:not(:disabled):not(.disabled).active,
.btn-gettingstarted-btn:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #16a0f7;
  border-color: #099bf7
}

.btn-gettingstarted-btn:not(:disabled):not(.disabled).active:focus,
.btn-gettingstarted-btn:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(65, 159, 218, .5);
  box-shadow: 0 0 0 .2rem rgba(65, 159, 218, .5)
}

.btn-mustard:not(:disabled):not(.disabled).active,
.btn-mustard:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #ffc81c;
  border-color: #ffc40f
}

.btn-mustard:not(:disabled):not(.disabled).active:focus,
.btn-mustard:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(222, 186, 73, .5);
  box-shadow: 0 0 0 .2rem rgba(222, 186, 73, .5)
}

.btn-bright-turquoise:not(:disabled):not(.disabled).active,
.btn-bright-turquoise:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #0bd3e9;
  border-color: #0bc8dc
}

.btn-bright-turquoise:not(:disabled):not(.disabled).active:focus,
.btn-bright-turquoise:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(47, 198, 214, .5);
  box-shadow: 0 0 0 .2rem rgba(47, 198, 214, .5)
}

.btn-hollywood-cerise:not(:disabled):not(.disabled).active,
.btn-hollywood-cerise:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #970091;
  border-color: #8a0085
}

.btn-hollywood-cerise:not(:disabled):not(.disabled).active:focus,
.btn-hollywood-cerise:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(210, 38, 203, .5);
  box-shadow: 0 0 0 .2rem rgba(210, 38, 203, .5)
}

.btn-cerise:not(:disabled):not(.disabled).active,
.btn-cerise:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #dd1e98;
  border-color: #d21d90
}

.btn-cerise:not(:disabled):not(.disabled).active:focus,
.btn-cerise:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(234, 99, 185, .5);
  box-shadow: 0 0 0 .2rem rgba(234, 99, 185, .5)
}

.btn-medium-purple:not(:disabled):not(.disabled).active,
.btn-medium-purple:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #8f33de;
  border-color: #8928dc
}

.btn-medium-purple:not(:disabled):not(.disabled).active:focus,
.btn-medium-purple:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(180, 119, 233, .5);
  box-shadow: 0 0 0 .2rem rgba(180, 119, 233, .5)
}

.btn-lavender-rose:not(:disabled):not(.disabled).active,
.btn-lavender-rose:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #ff67e7;
  border-color: #ff5ae5
}

.btn-lavender-rose:not(:disabled):not(.disabled).active:focus,
.btn-lavender-rose:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(222, 136, 209, .5);
  box-shadow: 0 0 0 .2rem rgba(222, 136, 209, .5)
}

.btn-dodger-blue:not(:disabled):not(.disabled).active,
.btn-dodger-blue:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #1854f9;
  border-color: #0c4bf8
}

.btn-dodger-blue:not(:disabled):not(.disabled).active:focus,
.btn-dodger-blue:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(101, 141, 251, .5);
  box-shadow: 0 0 0 .2rem rgba(101, 141, 251, .5)
}

.btn-electric-violet:not(:disabled):not(.disabled).active,
.btn-electric-violet:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #5c05ef;
  border-color: #5704e2
}

.btn-electric-violet:not(:disabled):not(.disabled).active:focus,
.btn-electric-violet:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(141, 75, 252, .5);
  box-shadow: 0 0 0 .2rem rgba(141, 75, 252, .5)
}

.btn-turquoise:not(:disabled):not(.disabled).active,
.btn-turquoise:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #19d29f;
  border-color: #18c697
}

.btn-turquoise:not(:disabled):not(.disabled).active:focus,
.btn-turquoise:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52, 202, 162, .5);
  box-shadow: 0 0 0 .2rem rgba(52, 202, 162, .5)
}

.btn-gull-gray:not(:disabled):not(.disabled).active,
.btn-gull-gray:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #7b8fa2;
  border-color: #74889d
}

.btn-gull-gray:not(:disabled):not(.disabled).active:focus,
.btn-gull-gray:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(135, 148, 162, .5);
  box-shadow: 0 0 0 .2rem rgba(135, 148, 162, .5)
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem hsla(208, 7%, 46%, .5);
  box-shadow: 0 0 0 .2rem hsla(208, 7%, 46%, .5)
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5);
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5);
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5);
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-sms:not(:disabled):not(.disabled).active,
.btn-outline-sms:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #47b4f9;
  border-color: #47b4f9
}

.btn-outline-sms:not(:disabled):not(.disabled).active:focus,
.btn-outline-sms:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(71, 180, 249, .5);
  box-shadow: 0 0 0 .2rem rgba(71, 180, 249, .5)
}

.btn-outline-mms:not(:disabled):not(.disabled).active,
.btn-outline-mms:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #420fb7;
  border-color: #420fb7
}

.btn-outline-mms:not(:disabled):not(.disabled).active:focus,
.btn-outline-mms:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(66, 15, 183, .5);
  box-shadow: 0 0 0 .2rem rgba(66, 15, 183, .5)
}

.btn-outline-voice:not(:disabled):not(.disabled).active,
.btn-outline-voice:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #e588d8;
  border-color: #e588d8
}

.btn-outline-voice:not(:disabled):not(.disabled).active:focus,
.btn-outline-voice:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(229, 136, 216, .5);
  box-shadow: 0 0 0 .2rem rgba(229, 136, 216, .5)
}

.btn-outline-email:not(:disabled):not(.disabled).active,
.btn-outline-email:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #74efcf;
  border-color: #74efcf
}

.btn-outline-email:not(:disabled):not(.disabled).active:focus,
.btn-outline-email:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(116, 239, 207, .5);
  box-shadow: 0 0 0 .2rem rgba(116, 239, 207, .5)
}

.btn-outline-fax:not(:disabled):not(.disabled).active,
.btn-outline-fax:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #c15bda;
  border-color: #c15bda
}

.btn-outline-fax:not(:disabled):not(.disabled).active:focus,
.btn-outline-fax:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(193, 91, 218, .5);
  box-shadow: 0 0 0 .2rem rgba(193, 91, 218, .5)
}

.btn-outline-post:not(:disabled):not(.disabled).active,
.btn-outline-post:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #fbc98e;
  border-color: #fbc98e
}

.btn-outline-post:not(:disabled):not(.disabled).active:focus,
.btn-outline-post:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem hsla(32, 93%, 77%, .5);
  box-shadow: 0 0 0 .2rem hsla(32, 93%, 77%, .5)
}

.btn-outline-flyers:not(:disabled):not(.disabled).active,
.btn-outline-flyers:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #fd8671;
  border-color: #fd8671
}

.btn-outline-flyers:not(:disabled):not(.disabled).active:focus,
.btn-outline-flyers:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem hsla(9, 97%, 72%, .5);
  box-shadow: 0 0 0 .2rem hsla(9, 97%, 72%, .5)
}

.btn-outline-flyers1:not(:disabled):not(.disabled).active,
.btn-outline-flyers1:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #ff866d;
  border-color: #ff866d
}

.btn-outline-flyers1:not(:disabled):not(.disabled).active:focus,
.btn-outline-flyers1:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255, 134, 109, .5);
  box-shadow: 0 0 0 .2rem rgba(255, 134, 109, .5)
}

.btn-outline-white:not(:disabled):not(.disabled).active,
.btn-outline-white:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #fff;
  border-color: #fff
}

.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.btn-outline-white:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem hsla(0, 0%, 100%, .5);
  box-shadow: 0 0 0 .2rem hsla(0, 0%, 100%, .5)
}

.btn-outline-martinique:not(:disabled):not(.disabled).active,
.btn-outline-martinique:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #32325c;
  border-color: #32325c
}

.btn-outline-martinique:not(:disabled):not(.disabled).active:focus,
.btn-outline-martinique:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(50, 50, 92, .5);
  box-shadow: 0 0 0 .2rem rgba(50, 50, 92, .5)
}

.btn-outline-bali-hai:not(:disabled):not(.disabled).active,
.btn-outline-bali-hai:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #8ca0b5;
  border-color: #8ca0b5
}

.btn-outline-bali-hai:not(:disabled):not(.disabled).active:focus,
.btn-outline-bali-hai:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(140, 160, 181, .5);
  box-shadow: 0 0 0 .2rem rgba(140, 160, 181, .5)
}

.btn-outline-bali-hai2:not(:disabled):not(.disabled).active,
.btn-outline-bali-hai2:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #f3f8fd;
  border-color: #f3f8fd
}

.btn-outline-bali-hai2:not(:disabled):not(.disabled).active:focus,
.btn-outline-bali-hai2:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(243, 248, 253, .5);
  box-shadow: 0 0 0 .2rem rgba(243, 248, 253, .5)
}

.btn-outline-maya-blue:not(:disabled):not(.disabled).active,
.btn-outline-maya-blue:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #47b4f9;
  border-color: #47b4f9
}

.btn-outline-maya-blue:not(:disabled):not(.disabled).active:focus,
.btn-outline-maya-blue:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(71, 180, 249, .5);
  box-shadow: 0 0 0 .2rem rgba(71, 180, 249, .5)
}

.btn-outline-maya-blue-btn:not(:disabled):not(.disabled).active,
.btn-outline-maya-blue-btn:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #4d7df6;
  border-color: #4d7df6
}

.btn-outline-maya-blue-btn:not(:disabled):not(.disabled).active:focus,
.btn-outline-maya-blue-btn:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(77, 125, 246, .5);
  box-shadow: 0 0 0 .2rem rgba(77, 125, 246, .5)
}

.btn-outline-alice-blue:not(:disabled):not(.disabled).active,
.btn-outline-alice-blue:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #f8fbff;
  border-color: #f8fbff
}

.btn-outline-alice-blue:not(:disabled):not(.disabled).active:focus,
.btn-outline-alice-blue:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(248, 251, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(248, 251, 255, .5)
}

.btn-outline-salmon:not(:disabled):not(.disabled).active,
.btn-outline-salmon:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #fbc98e;
  border-color: #fbc98e
}

.btn-outline-salmon:not(:disabled):not(.disabled).active:focus,
.btn-outline-salmon:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem hsla(32, 93%, 77%, .5);
  box-shadow: 0 0 0 .2rem hsla(32, 93%, 77%, .5)
}

.btn-outline-salmon1:not(:disabled):not(.disabled).active,
.btn-outline-salmon1:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #f9b25f;
  border-color: #f9b25f
}

.btn-outline-salmon1:not(:disabled):not(.disabled).active:focus,
.btn-outline-salmon1:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(249, 178, 95, .5);
  box-shadow: 0 0 0 .2rem rgba(249, 178, 95, .5)
}

.btn-outline-salmon-btn:not(:disabled):not(.disabled).active,
.btn-outline-salmon-btn:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #fd8671;
  border-color: #fd8671
}

.btn-outline-salmon-btn:not(:disabled):not(.disabled).active:focus,
.btn-outline-salmon-btn:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem hsla(9, 97%, 72%, .5);
  box-shadow: 0 0 0 .2rem hsla(9, 97%, 72%, .5)
}

.btn-outline-medium-orchid:not(:disabled):not(.disabled).active,
.btn-outline-medium-orchid:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #c15bda;
  border-color: #c15bda
}

.btn-outline-medium-orchid:not(:disabled):not(.disabled).active:focus,
.btn-outline-medium-orchid:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(193, 91, 218, .5);
  box-shadow: 0 0 0 .2rem rgba(193, 91, 218, .5)
}

.btn-outline-medium-orchid1:not(:disabled):not(.disabled).active,
.btn-outline-medium-orchid1:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #da56c8;
  border-color: #da56c8
}

.btn-outline-medium-orchid1:not(:disabled):not(.disabled).active:focus,
.btn-outline-medium-orchid1:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(218, 86, 200, .5);
  box-shadow: 0 0 0 .2rem rgba(218, 86, 200, .5)
}

.btn-outline-clicksend-blue:not(:disabled):not(.disabled).active,
.btn-outline-clicksend-blue:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #00a5ff;
  border-color: #00a5ff
}

.btn-outline-clicksend-blue:not(:disabled):not(.disabled).active:focus,
.btn-outline-clicksend-blue:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0, 165, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(0, 165, 255, .5)
}

.btn-outline-text-color:not(:disabled):not(.disabled).active,
.btn-outline-text-color:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #8a9eb3;
  border-color: #8a9eb3
}

.btn-outline-text-color:not(:disabled):not(.disabled).active:focus,
.btn-outline-text-color:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(138, 158, 179, .5);
  box-shadow: 0 0 0 .2rem rgba(138, 158, 179, .5)
}

.btn-outline-muted:not(:disabled):not(.disabled).active,
.btn-outline-muted:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #f3f8fd;
  border-color: #f3f8fd
}

.btn-outline-muted:not(:disabled):not(.disabled).active:focus,
.btn-outline-muted:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(243, 248, 253, .5);
  box-shadow: 0 0 0 .2rem rgba(243, 248, 253, .5)
}

.btn-outline-shocking:not(:disabled):not(.disabled).active,
.btn-outline-shocking:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #e588d8;
  border-color: #e588d8
}

.btn-outline-shocking:not(:disabled):not(.disabled).active:focus,
.btn-outline-shocking:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(229, 136, 216, .5);
  box-shadow: 0 0 0 .2rem rgba(229, 136, 216, .5)
}

.btn-outline-cotton-candy1:not(:disabled):not(.disabled).active,
.btn-outline-cotton-candy1:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #ff9aef;
  border-color: #ff9aef
}

.btn-outline-cotton-candy1:not(:disabled):not(.disabled).active:focus,
.btn-outline-cotton-candy1:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255, 154, 239, .5);
  box-shadow: 0 0 0 .2rem rgba(255, 154, 239, .5)
}

.btn-outline-spray:not(:disabled):not(.disabled).active,
.btn-outline-spray:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #74efcf;
  border-color: #74efcf
}

.btn-outline-spray:not(:disabled):not(.disabled).active:focus,
.btn-outline-spray:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(116, 239, 207, .5);
  box-shadow: 0 0 0 .2rem rgba(116, 239, 207, .5)
}

.btn-outline-spray1:not(:disabled):not(.disabled).active,
.btn-outline-spray1:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #38e8ba;
  border-color: #38e8ba
}

.btn-outline-spray1:not(:disabled):not(.disabled).active:focus,
.btn-outline-spray1:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(56, 232, 186, .5);
  box-shadow: 0 0 0 .2rem rgba(56, 232, 186, .5)
}

.btn-outline-breakdown-bg:not(:disabled):not(.disabled).active,
.btn-outline-breakdown-bg:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #f3f9fe;
  border-color: #f3f9fe
}

.btn-outline-breakdown-bg:not(:disabled):not(.disabled).active:focus,
.btn-outline-breakdown-bg:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(243, 249, 254, .5);
  box-shadow: 0 0 0 .2rem rgba(243, 249, 254, .5)
}

.btn-outline-hero:not(:disabled):not(.disabled).active,
.btn-outline-hero:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #31315c;
  border-color: #31315c
}

.btn-outline-hero:not(:disabled):not(.disabled).active:focus,
.btn-outline-hero:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(49, 49, 92, .5);
  box-shadow: 0 0 0 .2rem rgba(49, 49, 92, .5)
}

.btn-outline-gettingstarted-btn:not(:disabled):not(.disabled).active,
.btn-outline-gettingstarted-btn:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #47b4f9;
  border-color: #47b4f9
}

.btn-outline-gettingstarted-btn:not(:disabled):not(.disabled).active:focus,
.btn-outline-gettingstarted-btn:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(71, 180, 249, .5);
  box-shadow: 0 0 0 .2rem rgba(71, 180, 249, .5)
}

.btn-outline-mustard:not(:disabled):not(.disabled).active,
.btn-outline-mustard:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #ffd44f;
  border-color: #ffd44f
}

.btn-outline-mustard:not(:disabled):not(.disabled).active:focus,
.btn-outline-mustard:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255, 212, 79, .5);
  box-shadow: 0 0 0 .2rem rgba(255, 212, 79, .5)
}

.btn-outline-bright-turquoise:not(:disabled):not(.disabled).active,
.btn-outline-bright-turquoise:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #32e2f5;
  border-color: #32e2f5
}

.btn-outline-bright-turquoise:not(:disabled):not(.disabled).active:focus,
.btn-outline-bright-turquoise:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(50, 226, 245, .5);
  box-shadow: 0 0 0 .2rem rgba(50, 226, 245, .5)
}

.btn-outline-hollywood-cerise:not(:disabled):not(.disabled).active,
.btn-outline-hollywood-cerise:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #ca00c2;
  border-color: #ca00c2
}

.btn-outline-hollywood-cerise:not(:disabled):not(.disabled).active:focus,
.btn-outline-hollywood-cerise:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(202, 0, 194, .5);
  box-shadow: 0 0 0 .2rem rgba(202, 0, 194, .5)
}

.btn-outline-cerise:not(:disabled):not(.disabled).active,
.btn-outline-cerise:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #e648ad;
  border-color: #e648ad
}

.btn-outline-cerise:not(:disabled):not(.disabled).active:focus,
.btn-outline-cerise:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(230, 72, 173, .5);
  box-shadow: 0 0 0 .2rem rgba(230, 72, 173, .5)
}

.btn-outline-medium-purple:not(:disabled):not(.disabled).active,
.btn-outline-medium-purple:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #a75fe5;
  border-color: #a75fe5
}

.btn-outline-medium-purple:not(:disabled):not(.disabled).active:focus,
.btn-outline-medium-purple:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(167, 95, 229, .5);
  box-shadow: 0 0 0 .2rem rgba(167, 95, 229, .5)
}

.btn-outline-lavender-rose:not(:disabled):not(.disabled).active,
.btn-outline-lavender-rose:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #ff9aef;
  border-color: #ff9aef
}

.btn-outline-lavender-rose:not(:disabled):not(.disabled).active:focus,
.btn-outline-lavender-rose:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(255, 154, 239, .5);
  box-shadow: 0 0 0 .2rem rgba(255, 154, 239, .5)
}

.btn-outline-dodger-blue:not(:disabled):not(.disabled).active,
.btn-outline-dodger-blue:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #4a79fa;
  border-color: #4a79fa
}

.btn-outline-dodger-blue:not(:disabled):not(.disabled).active:focus,
.btn-outline-dodger-blue:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(74, 121, 250, .5);
  box-shadow: 0 0 0 .2rem rgba(74, 121, 250, .5)
}

.btn-outline-electric-violet:not(:disabled):not(.disabled).active,
.btn-outline-electric-violet:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #792bfb;
  border-color: #792bfb
}

.btn-outline-electric-violet:not(:disabled):not(.disabled).active:focus,
.btn-outline-electric-violet:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(121, 43, 251, .5);
  box-shadow: 0 0 0 .2rem rgba(121, 43, 251, .5)
}

.btn-outline-turquoise:not(:disabled):not(.disabled).active,
.btn-outline-turquoise:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #37e7b7;
  border-color: #37e7b7
}

.btn-outline-turquoise:not(:disabled):not(.disabled).active:focus,
.btn-outline-turquoise:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(55, 231, 183, .5);
  box-shadow: 0 0 0 .2rem rgba(55, 231, 183, .5)
}

.btn-outline-gull-gray:not(:disabled):not(.disabled).active,
.btn-outline-gull-gray:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #99a8b7;
  border-color: #99a8b7
}

.btn-outline-gull-gray:not(:disabled):not(.disabled).active:focus,
.btn-outline-gull-gray:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(153, 168, 183, .5);
  box-shadow: 0 0 0 .2rem rgba(153, 168, 183, .5)
}

.is-sticky .action .btn:first-child:not(:disabled):not(.disabled).active,
.is-sticky .action .btn:first-child:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #00a5ff;
  border-color: #00a5ff
}

.is-sticky .action .btn:first-child:not(:disabled):not(.disabled).active:focus,
.is-sticky .action .btn:first-child:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(0, 165, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(0, 165, 255, .5)
}

.is-sticky .action .btn:last-child:not(:disabled):not(.disabled).active,
.is-sticky .action .btn:last-child:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0084cc;
  border-color: #007cbf
}

.is-sticky .action .btn:last-child:not(:disabled):not(.disabled).active:focus,
.is-sticky .action .btn:last-child:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: 0 0 0 .2rem rgba(38, 179, 255, .5);
  box-shadow: 0 0 0 .2rem rgba(38, 179, 255, .5)
}

html {
  overflow-x: hidden;
  font-family: Arial
}

footer {
  padding-bottom: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 4.94444444rem 7.16666667rem
}

@media (min-width: 576px) and (max-width:991.98px) {
  footer {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 2.5rem 3.61111111rem
  }
}

@media (max-width: 767.98px) {
  footer {
    grid-template-columns: 1fr;
    padding: 1.27777778rem 1.83333333rem
  }
}

.footer-box {
  padding: 0 1.55555556rem
}

.footer-box:first-child {
  padding: 0 1.55555556rem 0 0
}

.footer-box:last-child {
  padding: 0 0 0 1.55555556rem
}

@media (max-width: 991.98px) {
  .footer-box {
    margin-bottom: 2.5rem
  }
}

@media (max-width: 767.98px) {

  .footer-box,
  .footer-box:first-child,
  .footer-box:last-child {
    padding: 0
  }
}

.footer-title {
  font-size: .77777778rem;
  text-transform: uppercase;
  color: #32325c;
  font-weight: 700
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0
}

.footer-links li {
  margin-bottom: 0rem
}

.footer-links li:last-child {
  margin-bottom: 0
}

.footer-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}
.footerLogo {
  margin-top: -1rem;
  margin-left: -1rem;
}
</style>
